import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../modal/modal.component';
import { ThemePalette } from '@angular/material/core';
import { MatSlideToggleChange, MatSlideToggle } from '@angular/material/slide-toggle';
import { FirebaseService } from 'src/services/firebase.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-icon',
  templateUrl: './modal-icon.component.html',
  styleUrls: ['./modal-icon.component.scss']
})
export class ModalIconComponent implements OnInit {
  file_out = ''
  @ViewChild('fileInput',{static:true})fileInput;
  color: ThemePalette = 'primary';
  checked = false;
  name:string;

  file: File | null = null;
  items: {};
  items_type: {};
  

  constructor(public dialogRef: MatDialogRef<ModalIconComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,public firebaseService: FirebaseService,private fb: FormBuilder) { }
  
  
  profileForm = this.fb.group({
    name: ['',Validators.required],
    user: ['',Validators.required],
    type:['',Validators.required],
    state:[false],
    uid:['']
  });


  ngOnInit() {
    this.name = 'NO'
    this.getAllUser();
    this.getAllTypes();
    console.log('thisdata: ', this.data);
    
   
    // this.profileForm.setValue(this.data); 
    this.profileForm = this.fb.group({
      name: [this.data['name'],Validators.required],
      user: [this.data['user'],Validators.required],
      type:[this.data['name'],Validators.required],
      state:[this.data['state']],
      uid:[this.data['uid'] || '']
    })

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  onClickFileInputButton(): void {
    this.fileInput.nativeElement.click();
  }
  onChangeFileInput(): void {
    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    this.file = files[0];
    this.uploadFile(this.file)
  }

  onChange(ob: MatSlideToggleChange) {
    if(ob.checked == true){
      this.name = 'SI'
    }else{
      this.name='NO'
    }
    // let matSlideToggle: MatSlideToggle = ob.source;	
  } 
  getAllUser(){
    this.firebaseService.getUserIcons().subscribe(result => {
      this.items = result;
    });
  }
  getAllTypes(){
    this.firebaseService.getTypes().subscribe(result => {
      this.items_type = result;
    });
  }
  uploadFile(file){
    this.firebaseService.uploadFileStorage(file);
  }
  saveModal(data){
    console.log('data: ', data);
    if(data.value.uid == '' || data.value.uid == undefined || data.value.uid == null){
      const data_send={
        name:data.value.name,
        state:data.value.state == true ? 1:0,
        user_id:data.value.user.payload.doc.data().uid,
        user_name:data.value.user.payload.doc.data().email,
        code:data.value.type.payload.doc.data().code
      }
      this.firebaseService.createIcons(data_send).then(
        res => {
          this.dialogRef.close(data_send);
        }
      );
    } else{
      const data_edit = {
        name:data.value.name,
        state:data.value.state == true ? 1:0,
        user_id:data.value.user.payload.doc.data().uid,
        user_name:data.value.user.payload.doc.data().email,
        code:data.value.type.payload.doc.data().code
      }
      this.firebaseService.updateIcon(data.value.uid,data_edit).then(res => {
        this.dialogRef.close(data_edit);
      });
    }
   
  }

}
