import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { FirebaseService } from 'src/services/firebase.service';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import * as firebase from 'firebase/app';
import { CategoryService } from 'src/services/category.service';
import { IconService } from 'src/services/icon.service';
import { AuthService } from '../services/auth.service';
import { MarkerService } from 'src/services/marker.service';
import { MessageService } from 'src/services/message.service';

type AOA = any[][];

@Component({
  selector: 'app-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.scss']
})
export class SheetComponent {
  displayedColumns = ['nro', 'ID', 'Nombre', 'Celular', 'Sitio_Web', 'Latitud', 'Longitud', 'Tipo', 'Categoria'];
  dataSource: ExcelData[];
  dataSourceExport: ExcelData[];

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  user_json = {};
  file_name = "";
  isLoadingResults = false;
  isDataNew = false;
  reader: FileReader = new FileReader();
  constructor(public firebaseService: FirebaseService, public categoryService: CategoryService,
    public iconService: IconService, public auth: AuthService, public markerService: MarkerService,
    public messageService: MessageService) {
    auth.user$.subscribe((res) => {
      if (res) {
        this.user_json = {
          uid: res['uid'],
          name: res['email']
        }
      }

    })
  }

  data: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'Bodegasdelperu.xlsx';
  list_ids: AOA = [];

  onFileChange(evt: any) {
    /* wire up file reader */
    this.isLoadingResults = true;
    const list_excel = []
    const target: DataTransfer = <DataTransfer>(evt.target);
    this.file_name = target.files[0].name;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    this.reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      this.data.splice(0, 1);
      for (let i = 0; i < this.data.length; i++) {
        const element = this.data[i];
        list_excel.push({
          id: element[0],
          name: element[1],
          cellphone: element[2],
          site: element[3],
          lat: element[4],
          long: element[5],
          tipo: element[6],
          categ: element[7]
        });

      }
      this.dataSource = list_excel;
      this.isLoadingResults = false;
    };
    this.reader.readAsBinaryString(target.files[0]);
  }


  export(): void {
    this.isLoadingResults = true;
    const data_save = this.data;
    const exp_list:any = [];
    const list_excel = []
    exp_list.push(['ID','NAME','NUMBER PHONE','SITE WEB','LATITUDE','LONGITUDE','TIPO','CATEGORIA']);
    let contadorup=0;
    let contadornew=0;
    for (let i = 0; i < data_save.length; i++) {
      const element = data_save[i];
      let data_icon;
      let data_category;

      const cat = this.markerService.getTypeCategory(element[6], element[7]);
      cat.subscribe(r => {
        data_icon = r.type[0];
        data_category = r.category[0];
        if (element[0]) {
          //Actualizacion de markers
          contadorup=contadorup+1;
          const data = {
            name: element[1],
            number_phone: element[2],
            site_web: element[3],
            latitude: element[4],
            longitude: element[5],
            code: data_icon['code'],
            type_category: data_category['code'],
            user_id: this.user_json['uid'],
            user_name: this.user_json['name'],
            icon: data_icon['url_icon'],
            address: element[8]?element[8]:'',
            coordinates: new firebase.firestore.GeoPoint(Number(element[4]), Number(element[5]))
          };
          this.markerService.updateMarkers(element[0], data).then(res => {
            this.isLoadingResults = false;
            this.messageService.open({ message: 'Registro Actualizado ' + element[1], addExtraClass: ['alert-success'] });

          }, err => {
            this.messageService.open({ message: 'Error al Actualizar ' + element[1], addExtraClass: ['alert-error'] });
            this.isLoadingResults = false;
          });
        } else {
          console.log('vamos a crear');
          const data = {
            name: element[1],
            number_phone: element[2],
            site_web: element[3],
            latitude: element[4],
            longitude: element[5],
            code: data_icon['code'],
            type_category: data_category['code'],
            user_id: this.user_json['uid'],
            user_name: this.user_json['name'],
            icon: data_icon['url_icon'],
            address: element[8]?element[8]:'',
            coordinates: new firebase.firestore.GeoPoint(Number(element[4]), Number(element[5]))
          };
          this.markerService.createMarkers(data).then(res => {
            console.log('res: ', res.id);
            res.get().then(da=>{
              contadornew = contadornew +1;
              console.log('daaa',da.data());
              this.isLoadingResults = false;
              list_excel.push({
                id: res.id,
                name: da.data().name,
                cellphone: da.data().number_phone,
                site: da.data().site_web,
                lat: da.data().latitude,
                long: da.data().longitude,
                tipo: da.data().code,
                categ: da.data().type_category
              });
              exp_list.push([res.id,da.data().name,da.data().number_phone,da.data().site_web,da.data().latitude,da.data().longitude,da.data().code,da.data().type_category]);
              console.log('data_save.length',data_save.length);
              console.log('contadorup',contadorup);
              console.log('contadornew',contadornew);
              if (data_save.length - contadorup === contadornew) {
                this.isDataNew = true;
                this.dataSourceExport = list_excel;
                console.log('this.dataSourceExport: ', this.dataSourceExport);
              }
              
            },err=>{
              this.messageService.open({ message: 'Error al Registrar ' + element[1], addExtraClass: ['alert-error'] });
              this.isLoadingResults = false;
            });
            this.isLoadingResults = false;
            this.messageService.open({ message: 'Registro Exitoso ' + element[1], addExtraClass: ['alert-success'] });
          }, err => {
            this.messageService.open({ message: 'Error al Registrar ' + element[1], addExtraClass: ['alert-error'] });
            this.isLoadingResults = false;
          });
        }
      }, err => {
        this.messageService.open({ message: 'Ocurrio un Error ' + err, addExtraClass: ['alert-error'] });
        this.isLoadingResults = false;
      });
    }    
    this.list_ids = exp_list;


  }
  exportExcel(): void {
    if(this.list_ids.length>0){
      /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.list_ids);
    console.log('this.data: ', this.list_ids);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Bodegas');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    }else{
      this.messageService.open({ message: 'No hay registros que exportar', addExtraClass: ['alert-info'] });
    }
    
  };
  clearData() {
    this.dataSource = [];
    this.file_name = "";
    this.reader.result.slice;
  }

}

export interface ExcelData {
  id: string;
  name: string;
  cellphone: string;
  site: string;
  lat: string;
  long: string;
  tipo: string;
  categ: string;
}

const ELEMENT_DATA = [];
