import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  admin:boolean;

  constructor(public auth:AuthService) {
    auth.user$.subscribe((res)=>{
      if (res['state'] == 1) {
        this.admin = true;
      }else{this.admin=false}
    })
   }

  ngOnInit() {
  }
  avatar(){
    return ('../../assets/images/avatar.png');
  }

}
