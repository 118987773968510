import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSlideToggleChange, PageEvent, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { CategoryService } from 'src/services/category.service';
import { MessageService } from 'src/services/message.service';
import { AlertDeleteComponent } from '../shared/alert-delete/alert-delete.component';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  displayedColumns: string[] = ['select', 'nro', 'name', 'code', 'state', 'options'];
  dataSource: MatTableDataSource<CategoryElement>;
  dataList = [];
  items: Array<any>;
  selection = new SelectionModel<CategoryElement>(true, []);
  color = "primary";
  checked = false;
  // MatPaginator Output
  //Save first document in snapshot of items received
  firstInResponse: any = [];

  //Save last document in snapshot of items received
  lastInResponse: any = [];
  //Keep the array of first document of previous pages
  prev_strt_at: any = [];

  //Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;

  //Disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = false;
  // MatPaginator Output
  pageEvent: PageEvent;
  length = 5;
  pageSize = 5;
  pageIndex: number = 0;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(public categoryService: CategoryService, public dialog: MatDialog, public messageService: MessageService) { }


  ngOnInit() {
    this.getCount();
    this.getAll(this.pageSize)
  }
  listCategories(){
    this.getCount();
    this.getAll(this.pageSize)
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    if (this.dataSource) {
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: CategoryElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  getCount() {
    this.categoryService.getCount().subscribe(result => {
      this.length = result['page_size'];
    });
  }
  getAll(limit) {
    this.categoryService.getCategories(limit).subscribe(result => {
      this.dataList = [];
      if (!result.length) {
        return false;
      }
      this.firstInResponse = result[0].payload.doc;
      this.lastInResponse = result[result.length - 1].payload.doc;
      this.items = result;
      result.forEach(r => {
        this.dataList.push({
          id: r.payload.doc.id,
          name: r.payload.doc.data()['name'],
          code: r.payload.doc.data()['code'],
          state: r.payload.doc.data()['state']
        })

      })
      //Initialize values
      this.prev_strt_at = [];
      this.pagination_clicked_count = 0;
      this.disable_next = false;
      this.disable_prev = false;

      //Push first item to use for Previous action
      this.push_prev_startAt(this.firstInResponse);

      this.dataSource = new MatTableDataSource(this.dataList);

    })
  }
  pageLoad($event) {
    if ($event.pageIndex === this.pageIndex + 1) {
      this.nextPage($event.pageSize);
    } else if ($event.pageIndex === this.pageIndex - 1) {
      this.prevPage($event.pageSize);
    }
    else {
      this.getAll($event.pageSize);
    }
    this.pageIndex = $event.pageIndex;

  }
  //Show previous set 
  prevPage(pagesize) {
    this.disable_prev = true;
    this.categoryService.getPrevPage(this.get_prev_startAt(), this.firstInResponse, pagesize).subscribe(response => {
      this.firstInResponse = response.docs[0];
      this.lastInResponse = response.docs[response.docs.length - 1];
      this.dataList = [];
      response.docs.forEach(r => {
        this.dataList.push({
          id: r.id,
          name: r.data()['name'],
          code: r.data()['code'],
          state: r.data()['state']
        })

      })
      //Maintaing page no.
      this.pagination_clicked_count--;

      //Pop not required value in array
      this.pop_prev_startAt(this.firstInResponse);

      //Enable buttons again
      this.disable_prev = false;
      this.disable_next = false;
      this.dataSource = new MatTableDataSource(this.dataList);

    }, error => {
      this.disable_prev = false;
    });
  }
  nextPage(pagesize) {
    this.disable_next = true;
    this.categoryService.getNextPage(pagesize, this.lastInResponse).subscribe(response => {

      if (!response.docs.length) {
        this.disable_next = true;
        return;
      }

      this.firstInResponse = response.docs[0];

      this.lastInResponse = response.docs[response.docs.length - 1];

      this.dataList = [];
      response.docs.forEach(r => {
        this.dataList.push({
          id: r.id,
          name: r.data()['name'],
          code: r.data()['code'],
          state: r.data()['state']
        })

      })

      this.pagination_clicked_count++;

      this.push_prev_startAt(this.firstInResponse);

      this.disable_next = false;
      this.dataSource = new MatTableDataSource(this.dataList);
    }, error => {
      this.disable_next = false;
    });
  }
  //Add document
  push_prev_startAt(prev_first_doc) {
    this.prev_strt_at.push(prev_first_doc);
  }
  //Remove not required document 
  pop_prev_startAt(prev_first_doc) {
    this.prev_strt_at.forEach(element => {
      if (prev_first_doc.data().id == element.data().id) {
        element = null;
      }
    });
  }
  //Return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_strt_at.length > (this.pagination_clicked_count + 1))
      this.prev_strt_at.splice(this.prev_strt_at.length - 2, this.prev_strt_at.length - 1);
    return this.prev_strt_at[this.pagination_clicked_count - 1];
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(DialogCategoryComponent, {
      width: '80%',
      data: { title: 'Nuevo', name: '', code: '', state: false },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result['name'] != '' && result['code'] != '') {
          this.categoryService.createCategory(result).then((r) => {
            this.getCount();
            this.getAll(this.pageSize);
            this.messageService.open({ message: 'Registro Realizado con éxito', addExtraClass: ['alert-success'] });
          })

        } else {
          this.messageService.open({ message: 'No se realizó el registro, Valores vacios', addExtraClass: ['alert-error'] });
          this.getCount();
          this.getAll(this.pageSize);
        }
      } else {
        this.messageService.open({ message: 'Se cancelo!', addExtraClass: ['alert-info'] });
        this.getCount();
        this.getAll(this.pageSize);
      }
    });
  }
  editDialog(data): void {
    data['title']="Editar"
    const dialogRef = this.dialog.open(DialogCategoryComponent, {
      width: '80%',
      data: data,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.categoryService.updateCategory(result['id'], { 'name': result['name'], 'code': result['code'], 'state': result['state'] });
        this.getCount();
        this.getAll(this.pageSize);
        this.messageService.open({ message: 'Se realizo la actualizacion:', addExtraClass: ['alert-success'] });

      } else {
        this.messageService.open({ message: 'Se cancelo!', addExtraClass: ['alert-info'] });
        this.getCount();
        this.getAll(this.pageSize);
      }
    });
  }
  deleteCateg(data) {
    data['title']="¿Desea eliminar el registro?";
    data['message']="Se eliminara el registro: " + data['name'];
    const dialogRef = this.dialog.open(AlertDeleteComponent, {
      width: '50%',
      data: data,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.categoryService.deleteCategory(data['id']).then((r) => {
          this.getCount();
          this.getAll(this.pageSize);
          this.messageService.open({ message: 'Se eliminó con exito', addExtraClass: ['alert-success'] });
        }, err => {
          this.messageService.open({ message: 'No se puedo eliminar el registro!', addExtraClass: ['alert-error'] });
        })
      }else{
        this.messageService.open({ message: 'Se cancelo!', addExtraClass: ['alert-info'] });
      }
    });
    

  }

}
export interface CategoryElement {
  id: string;
  name: string;
  code: string;
  state: boolean;
}



@Component({
  selector: 'dialog-category',
  templateUrl: 'category.dialog.html',
})
export class DialogCategoryComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface DialogData {
  title: string;
  name: string;
  code: string;
  state: boolean;
}
