import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MapsComponent } from './maps/maps.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSliderModule} from '@angular/material/slider';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AgmCoreModule } from '@agm/core';
import {MatChipsModule} from '@angular/material/chips';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FirebaseService } from 'src/services/firebase.service';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { AuthService } from './services/auth.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { AngularFireAuthModule } from '@angular/fire/auth';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './shared/login/login.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { RegisterComponent } from './register/register.component';
import { ModalComponent } from './shared/modal/modal.component';
import { MainComponent } from './shared/main/main.component';
import { UserComponent } from './user/user.component';
import {MatTableModule} from '@angular/material/table';
import { IconComponent } from './icon/icon.component';
import { ModalIconComponent } from './shared/modal-icon/modal-icon.component';
import { SheetComponent } from './sheet/sheet.component';
import { DataComponent } from './data/data.component';
import { ToastBarComponent } from './shared/toast-bar/toast-bar.component';
import { TerminosComponent } from './terminos/terminos.component';
import { PdfComponent } from './shared/pdf/pdf.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MarkerService } from 'src/services/marker.service';
import { IconService } from 'src/services/icon.service';
import { CategoryService } from 'src/services/category.service';
import { TypeService } from 'src/services/type.service';
import { MessageService } from 'src/services/message.service';
import { RegisBodegaComponent } from './shared/regis-bodega/regis-bodega.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { DialogMarkerComponent } from './shared/dialog-marker/dialog-marker.component';
import {CoreModule} from './core/core.module';
import { CategoryComponent,DialogCategoryComponent } from './category/category.component';
import { MarkerComponent, modalMarkerComponent } from './marker/marker.component';
import { MatCheckboxModule } from '@angular/material';
import { AlertDeleteComponent } from './shared/alert-delete/alert-delete.component';

@NgModule({
  declarations: [
    AppComponent,
    MapsComponent,
    LoginComponent,
    NotFoundComponent,
    RegisterComponent,
    ModalComponent,
    MainComponent,
    UserComponent,
    IconComponent,
    ModalIconComponent,
    SheetComponent,
    DataComponent,
    ToastBarComponent,
    TerminosComponent,
    PdfComponent,
    RegisBodegaComponent,
    DialogMarkerComponent,
    CategoryComponent,
    MarkerComponent,
    DialogCategoryComponent,
    AlertDeleteComponent,
    modalMarkerComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxUsefulSwiperModule,
    FlexLayoutModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatSelectModule,
    MatPaginatorModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatTooltipModule,
    MatSliderModule,
    MatCardModule,
    MatMenuModule,
    HttpClientModule,
    MatTableModule,
    MatFormFieldModule,
    PdfViewerModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBhFekVqr-KHzjNWzAbRVb3xnpy6F_JMpk',
      libraries: ['places'],
    }),
    InfiniteScrollModule,
    AgmSnazzyInfoWindowModule

  ],
  entryComponents: [
    ModalComponent,
    ModalIconComponent,
    ToastBarComponent,
    DialogMarkerComponent,
    DialogCategoryComponent,
    AlertDeleteComponent,
    modalMarkerComponent
    ],
  providers: [FirebaseService,AuthService,AuthGuard,MarkerService,CategoryService,IconService,TypeService,MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
