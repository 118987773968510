import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import { GeoFirestore, GeoCollectionReference, GeoQuery } from 'geofirestore';
import * as geofirex from 'geofirex'; 
@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  lista = [];
  url;

  constructor(public db: AngularFirestore,public store:AngularFireStorage,private dbd: AngularFireDatabase) { }

  getUsers(limit){
    return this.db.collection('users',ref => ref.orderBy('email',"asc").limit(limit)).snapshotChanges();
  }
  getContUsers(){
    return this.db.collection('users',ref=> ref.orderBy('email')).get();
    // return this.db.collection('users', ref=> ref.orderBy('email')).snapshotChanges();
  }
  searchUserIcon(search,limit){
    return this.db.collection('users',ref=> ref.orderBy('email').startAt(search).endAt(search+'\uf8ff').limit(limit)).get();
  }
  getPrevPageUsers(prevestart,firtsreponse,limit){
    return this.db.collection('users',ref => ref.orderBy('email',"asc").startAt(prevestart).endBefore(firtsreponse).limit(limit)).get();
  }
  getNextPageUsers(limit,lastresponse){
    return this.db.collection('users',ref => ref.limit(limit).orderBy('email',"asc").startAfter(lastresponse)).get();
  }
  searchUser(email){
    return this.db.collection('users',ref => ref.where('email','==',email).where('state','==',1)).snapshotChanges();
  }
  updateUserState(id,val){
    return this.db.collection('users').doc(id).update({state:val});
  }
  getAllMarkers(){
    return this.db.collection('markers').snapshotChanges();
  }
 
  getIcon(id){
    return this.db.collection('icons',ref => ref.where('user_id','==',id).where('state','==',1)).snapshotChanges();
  }

  getIconsAll(){
    return this.db.collection('icons',ref => ref.orderBy('name')).snapshotChanges();
  }
  getUserIcons(){
    return this.db.collection('users').snapshotChanges();
  }
  getTypes(){
    return this.db.collection('types').snapshotChanges();
  }
  getUser(email){
    return this.db.collection('users',ref=>ref.where('email','==',email)).valueChanges();
  }
  getType(code){
    return this.db.collection('types',ref=>ref.where('code','==',code)).valueChanges();
  }
  
  uploadFileStorage(file){
    const storageRef  = firebase.storage().ref();
    const uploadTask = storageRef.child(`Icon/${file.name}`).put(file);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        // in progress
        const snap = snapshot as firebase.storage.UploadTaskSnapshot;
      },
      (error) => {
        // fail
        console.log(error);
      },
      () => {
        // success
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          this.url = downloadURL;
        });
      }
    );
  }
  createIcons(data){
    data['url_icon']=this.url;
    return this.db.collection('icons').add(data);
  }
  getIconIdData(id){
    return this.db.collection('icons').doc(id).valueChanges();
  }
  updateIcon(id,data){
    return this.db.collection('icons').doc(id).update(data);
  }
  filterIcon(code){
    return this.db.collection('markers', ref=> ref.where('code','==',code)).snapshotChanges();
  }
  deleteIcon(id){
    return this.db.collection('icons').doc(id).delete();
  }
  updateMarker(id,data){
  return this.db.collection('markers').doc(id).update(data);
  }
  createData(data){
    return this.db.collection('markers').add(data);
    
  }
  getTerm(){
    return this.db.collection('terms', ref => ref.where('state','==',true).limit(1)).snapshotChanges();
  }
  getNotifys(){
    return this.db.collection('notify', ref => ref.where('state','==',true)).snapshotChanges();
  }
  markersRadius(lat,long,rad){
    // Create a Firestore reference
const firestore = firebase.firestore();

// Create a GeoFirestore reference
const geofirestore = new GeoFirestore(firestore);

// Create a GeoCollection reference
const geocollection = geofirestore.collection('markers');

// Add a GeoDocument to a GeoCollection
// geocollection.add(da)  ({
//   name: 'Geofirestore',
//   score: rad,
//   // The coordinates field must be a GeoPoint!
//   coordinates: new firebase.firestore.GeoPoint(lat, long)
// })
const up = geocollection.doc('0UqOeBYEhTOCiMmDF2Vu').update({coordinates: new firebase.firestore.GeoPoint(lat, long)})

// Create a GeoQuery based on a location
const query = geocollection.near({ center: new firebase.firestore.GeoPoint(lat, long), radius: rad });

// Get query (as Promise)
query.get().then((value) => {
  // All GeoDocument returned by GeoQuery, like the GeoDocument added above
  console.log('valuesss',value.docs);
});
query.onSnapshot(re=>{
  re.forEach(res=>{
    console.log('dataa',res.data());
  })
})
    

//     // Create a Firestore reference
// const firestore = firebase.firestore();
 
// // Create a GeoFirestore reference
// const geofirestore = new GeoFirestore(firestore);
 
// // Create a GeoCollection reference
// const geocollection = geofirestore.collection('markers');
 
// // // Add a GeoDocument to a GeoCollection
// // geocollection.add({
// //   name: 'Geofirestore',
// //   score: rad,
// //   // The coordinates field must be a GeoPoint!
// //   coordinates: new firebase.firestore.GeoPoint(lat, long)
// // })
 
// // // Create a GeoQuery based on a location
// const query = geocollection.near({ center: new firebase.firestore.GeoPoint(lat, long), radius: rad });
 
// // Get query (as Promise)
// query.get().then((value) => {
//   // All GeoDocument returned by GeoQuery, like the GeoDocument added above
//   console.log('vamos a',value);
// });
// // query.onSnapshot(data =>{
// //   data.forEach(res=>{
// //     console.log('dataa',res.data());
// //   });
// // });
// query.onSnapshot(data=>{
//   data.forEach(res =>{
//     res.data()
//   })
// })


 //Geofire
// Create a GeoFirestore reference
// const geofirestore: GeoFirestore = new GeoFirestore(db);

// Create a GeoCollection reference
// const geocollection: GeoCollectionReference = geofirestore.collection('markers');
// geocollectionDrivers.add({
//   name: 'Geofirestore',
//   score: rad,
//   // The coordinates field must be a GeoPoint!
//   coordinates: new firebase.firestore.GeoPoint(lat, long)
// })

// const query: GeoQuery = geocollection.near({ 
//         center: new firebase.firestore.GeoPoint(lat, long), 
//         radius: rad 
//     });
//     query.onSnapshot(gquerySnapshot => {
//         // gquerySnapshot.forEach(res => {
//         //     console.log('ssss',res);
//         //     this.db.collection('markers').doc(res.id).valueChanges().subscribe(resp=>{
//         //       console.log('resp: ', resp);
//         //     })
//         // })
//     });
//     query.get().then((value) => {
//       // All GeoDocument returned by GeoQuery, like the GeoDocument added above
//       console.log('dataaa',value.docs);
//       // value.docs.forEach(re=>{
//       //   console.log('esss',re.data());
//       // })
//     });

  }

}
