import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  hide = true;
  defaultConf = {
    message: '',
    actionButtonLabel: 'Cerrar',
    verticalPosition: 'top',
    horizontalPosition: 'right',
    action: true,
    setAutoHide: true,
    autoHide: 5000,
    addExtraClass: []
  };

  constructor(private _snackBar: MatSnackBar) { }
  open(parameters) {
    const params = Object.assign({}, this.defaultConf, parameters);
    const config = new MatSnackBarConfig();
    config.verticalPosition = params.verticalPosition;
    config.horizontalPosition = params.horizontalPosition;
    config.duration = params.setAutoHide ? params.autoHide : 0;
    config.panelClass = params.addExtraClass;
    this._snackBar.open(params.message, params.action ? params.actionButtonLabel : undefined, config);
  }
}
