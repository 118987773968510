import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { AuthService } from '../services/auth.service';
import { FirebaseService } from 'src/services/firebase.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';

export interface UserData {
  id: string;
  name: string;
  progress: string;
  color: string;
  option: string;
}
export interface Section {
  name: string;
  description: string;
  icon: string;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
  'maroon', 'red', 'orange', 'yellow', 'olive', 'green', 'purple', 'fuchsia', 'lime', 'teal',
  'aqua', 'blue', 'navy', 'black', 'gray'
];
const NAMES: string[] = [
  'Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack', 'Charlotte', 'Theodore', 'Isla', 'Oliver',
  'Isabella', 'Jasper', 'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'
];

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  public type_mode='over';

  displayedColumns: string[] = ['id', 'name', 'email', 'state','option'];
  dataSource: MatTableDataSource<UserData>;
  dataList = [];
  items: Array<any>;
  //Save first document in snapshot of items received
  firstInResponse: any = [];

  //Save last document in snapshot of items received
  lastInResponse: any = [];

  //Keep the array of first document of previous pages
  prev_strt_at: any = [];

  //Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;

  //Disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = false;
  // MatPaginator Output
  pageEvent: PageEvent;
  length = 5;
  pageSize = 5;
  pageIndex:number = 0;
  color='primary'
  url_term='';
  checked = false;
  registros: Section[] = [
    {
      name: 'Incidencias',
      description: '',
      icon: 'keyboard'
    },
  ];
  reportes: Section[] = [
    {
      name: 'Incidencias Usuarios',
      description: '',
      icon: 'reorder'
    },
  ];
  about = [
    {
      name: 'Inti Tec',
      description: '',
      icon: 'business',
      code:"INTITEC"
    },
    {
      name: 'Términos y',
      description:"Condiciones",
      icon:'menu_book',
      code:"TERM"
    }
  ]
  user_json = {};
  admin: boolean;
  indeterminate = true;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(public auth: AuthService,public firebaseService: FirebaseService,private breakpointObserver: BreakpointObserver) {
    // Create 100 users
    // const users = Array.from({length: 100}, (_, k) => createNewUser(k + 1));

    // Assign the data to the data source for the table to render
    // this.dataSource = new MatTableDataSource(users);
    auth.user$.subscribe((res) => {
      if(res){
        this.user_json = {
          uid: res['uid'],
          name: res['email']
        }
        this.indeterminate = false;
        this.admin = true;
      }else{
        this.admin = false
        this.indeterminate = false;
      }
    })
    this.getAll(this.pageSize);
    this.getCount();
  }

  ngOnInit() {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
    this.readTerm();
  }
  getCount(){
    this.firebaseService.getContUsers().subscribe(res => {
      this.length = res.size;
    });
  }
  getAll(limit){
    this.firebaseService.getUsers(limit).subscribe(result => {
      if (!result.length) {
        return false;
      }
      this.firstInResponse = result[0].payload.doc;
      this.lastInResponse = result[result.length - 1].payload.doc;
      this.items = result;
      this.dataList=[];
      for (const i in this.items) {
        if (result.hasOwnProperty(i)) {
          const element = this.items[i];
          this.dataList.push({
            id: element.payload.doc.id,
            name: element.payload.doc.data().displayName,
            email: element.payload.doc.data().email,
            state: element.payload.doc.data().state,
            option:element.payload.doc.id,
          })
        }
      }
      //Initialize values
      this.prev_strt_at = [];
      this.pagination_clicked_count = 0;
      this.disable_next = false;
      this.disable_prev = false;

      //Push first item to use for Previous action
      this.push_prev_startAt(this.firstInResponse);
      this.dataSource = new MatTableDataSource(this.dataList);
    });
  }

  applyFilter(event: Event) {
    
    const filterValue = (event.target as HTMLInputElement).value;
    this.firebaseService.searchUserIcon(filterValue,this.pageSize).subscribe(response =>{
      this.dataList = [];
      for (let item of response.docs) {
        this.dataList.push({
          id: item.data().uid,
          name: item.data().displayName,
          email: item.data().email,
          state: item.data().state,
          option:item.data().uid,
        })
      }
      // this.length = response.docs.length;
      this.dataSource = new MatTableDataSource(this.dataList);
      
    });
    // this.dataSource.filter = filterValue.trim().toLowerCase();

    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }
  onChange(id,ob: MatSlideToggleChange) {
    if(ob.checked == true){
      this.firebaseService.updateUserState(id,1).then(res =>{
        console.log('');
      });
    }else{
      this.firebaseService.updateUserState(id,0).then(res =>{
        console.log('');
      });
    }
    // let matSlideToggle: MatSlideToggle = ob.source;	
  } 
  pageLoad($event){
    if($event.pageIndex === this.pageIndex + 1){
      this.nextPage($event.pageSize);
     }else if($event.pageIndex === this.pageIndex - 1){
       this.prevPage($event.pageSize);
     }
     else{
       this.getAll($event.pageSize);
     }
     this.pageIndex = $event.pageIndex;

  }
  //Show previous set 
  prevPage(pagesize) {
    this.disable_prev = true;
    this.firebaseService.getPrevPageUsers(this.get_prev_startAt(),this.firstInResponse,pagesize).subscribe(response =>{
      this.firstInResponse = response.docs[0];
      this.lastInResponse = response.docs[response.docs.length - 1];
      this.dataList=[];
      for (let item of response.docs) {
        this.dataList.push({
          id: item.data().uid,
          name: item.data().displayName,
          email: item.data().email,
          state: item.data().state,
          option:item.data().uid,
        })
      }
      //Maintaing page no.
      this.pagination_clicked_count--;

      //Pop not required value in array
      this.pop_prev_startAt(this.firstInResponse);

      //Enable buttons again
      this.disable_prev = false;
      this.disable_next = false;
      this.dataSource = new MatTableDataSource(this.dataList);

    },error =>{
      this.disable_prev = false;
    });
  }
  nextPage(pagesize) {
    this.disable_next = true;
      this.firebaseService.getNextPageUsers(pagesize,this.lastInResponse).subscribe(response =>{
        
        if (!response.docs.length) {
          this.disable_next = true;
          return;
        }

        this.firstInResponse = response.docs[0];

        this.lastInResponse = response.docs[response.docs.length - 1];
  
        this.dataList=[];
        for (let item of response.docs) {
          this.dataList.push({
            id: item.data().uid,
            name: item.data().displayName,
            email: item.data().email,
            state: item.data().state,
            option:item.data().uid,
          })
        }

        this.pagination_clicked_count++;

        this.push_prev_startAt(this.firstInResponse);

        this.disable_next = false;
        this.dataSource = new MatTableDataSource(this.dataList);
      },error =>{
        this.disable_next = false;
      });
  }
  //Add document
  push_prev_startAt(prev_first_doc) {
    this.prev_strt_at.push(prev_first_doc);
  }
  //Remove not required document 
  pop_prev_startAt(prev_first_doc) {
    this.prev_strt_at.forEach(element => {
      if (prev_first_doc.data().id == element.data().id) {
        element = null;
      }
    });
  }
  //Return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_strt_at.length > (this.pagination_clicked_count + 1))
      this.prev_strt_at.splice(this.prev_strt_at.length - 2, this.prev_strt_at.length - 1);
    return this.prev_strt_at[this.pagination_clicked_count - 1];
  }
  goIntitec(type) {
    if(type.code === "INTITEC"){
      window.open('https://intitecperu.com/contactus', '_blank');
    }
    if(type.code === "TERM"){
      window.open(this.url_term, '_blank');
    }
  }
  readTerm(){
    this.firebaseService.getTerm().subscribe(res =>{
      this.url_term = res[0].payload.doc.data()['url'];
    });
  }
  avatar(){
    return ('../../assets/images/avatar.png');
  }
}

/** Builds and returns a new User. */
function createNewUser(id: number): UserData {
  const name = NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' +
      NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';

  return {
    id: id.toString(),
    name: name,
    progress: Math.round(Math.random() * 100).toString(),
    color: COLORS[Math.round(Math.random() * (COLORS.length - 1))],
    option:id.toString()
  };
}
