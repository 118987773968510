import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import { GeoFirestore } from 'geofirestore';
import * as geofirex from 'geofirex';
import { Observable, combineLatest, of, BehaviorSubject } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { tap, scan, take } from 'rxjs/operators'
// Options to reproduce firestore queries consistently
interface QueryConfig {
  path: string, // path to collection
  field: string, // field to orderBy
  limit?: number, // limit per query
  reverse?: boolean, // reverse order?
  prepend?: boolean // prepend to source?
  type_code: string
}


@Injectable({
  providedIn: 'root'
})
export class MarkerService {
  // Source data
  private _done = new BehaviorSubject(false);
  private _loading = new BehaviorSubject(false);
  private _data = new BehaviorSubject([]);

  private query: QueryConfig;

  // Observable data
  data: Observable<any>;
  done: Observable<boolean> = this._done.asObservable();
  loading: Observable<boolean> = this._loading.asObservable();

  constructor(public db: AngularFirestore, public store: AngularFireStorage, private dbd: AngularFireDatabase) { }

  // Create a Firestore reference
  firestore = firebase.firestore();

  // Create a GeoFirestore reference
  geofirestore = new GeoFirestore(this.firestore);
  all$: Observable<{ type: any[], category: any[] }>

  getMarkersAll(limit) {
    // return this.db.collection('markers', ref => ref.orderBy('name', "asc").limit(limit)).valueChanges();
    return this.db.collection('markers',ref => ref.orderBy('name',"asc").limit(limit)).snapshotChanges();
  }
  getCount(){
    return this.db.collection('markers',ref=> ref.orderBy('name')).get();
    // const ref = this.db.collection('markers').doc('count');
    // return ref.valueChanges();
  }
  getMarkersAllCount() {
    return this.db.collection('markers', ref => ref.orderBy('countAll').limit(1)).valueChanges();
  }
  searchMarker(search, limit) {
    return this.db.collection('markers', ref => ref.orderBy('name').startAt(search).endAt(search + '\uf8ff').limit(limit)).get();
  }
  getPrevPageMarkers(prevestart, firtsreponse, limit) {
    return this.db.collection('markers', ref => ref.orderBy('name', "asc").startAt(prevestart).endBefore(firtsreponse).limit(limit)).get();
  }
  getNextPageMarkers(limit, lastresponse) {
    return this.db.collection('markers', ref => ref.limit(limit).orderBy('name', "asc").startAfter(lastresponse)).get();
  }

  getMarkersRadio(lat, long, radio) {
    // Create a GeoCollection reference
    const geocollection = this.geofirestore.collection('markers');
    // Create a GeoQuery based on a location
    const query = geocollection.near({ center: new firebase.firestore.GeoPoint(lat, long), radius: radio }).get();
    const categogy = this.db.collection('categories', ref => ref.where('state', '==', true)).valueChanges();
    return {query,categogy};
  }

  getTypeCategory(code_type, code_categ) {
    let type_;
    let categ_;
    const types_ = this.db.collection('icons', ref => ref.where('code', '==', code_type).where('state', '==', 1)).valueChanges();
    const categogy = this.db.collection('categories', ref => ref.where('code', '==', code_categ).where('state', '==', true)).valueChanges();
    return combineLatest(
      types_, categogy
    ).pipe(
      map(([type, category]) => {
        return { type, category }
      })
    )

  };
  createMarkers(data) {
    // Create a GeoCollection reference
    const geocollection = this.geofirestore.collection('markers');
    // Add a GeoDocument to a GeoCollection
    return geocollection.add(data);
  }
  updateMarkers(id, data) {
    const geocollection = this.geofirestore.collection('markers');
    return geocollection.doc(id).update(data);
    // return this.db.collection('markers').doc(id).update(data);
  }
  deleteMarkers(ids) {
    const obj = {};
    const listdata = [];
    for (let i = 0; i < ids.length; i++) {
      const element = ids[i];
      this.db.collection('markers').doc(element['id']).delete();
      listdata.push({ id: element['id'] });
    }
    obj['status'] = 'success';
    obj['data'] = listdata;
    return obj
  }
  deleteAllMarkers(){
    let collectionRef = this.db.collection('markers').get();
    collectionRef.subscribe(r=>{
      r.docs.forEach(res=>{
      console.log("MarkerService -> deleteAllMarkers -> res", res)
        res.ref.delete();
      })
    })
    
  }
  getSearchBussines(start, end) {
    return this.db.collection('markers', ref => ref.limit(5).orderBy('name').startAt(start).endAt(end)).valueChanges();
  }

  initMarkerfilters(path, field,code, opts?) {
    this.query = {
      path,
      field,
      limit: 7,
      reverse: false,
      prepend: false,
      type_code: code,
      ...opts
      
    }
    const first = this.db.collection(this.query.path, ref => {
      return ref
        .where('type_category', '==', code)
        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
        .limit(this.query.limit)
    })
    this.mapAndUpdate(first)
    // Create the observable array for consumption in components
    this.data = this._data.asObservable().pipe(
      scan((acc, val) => {
        return this.query.prepend ? val.concat(acc) : acc.concat(val)
      })
    )
  }
  // Retrieves additional data from firestore
  more() {
    const cursor = this.getCursor()

    const more = this.db.collection(this.query.path, ref => {
      return ref
        .where('type_category', '==', this.query.type_code)
        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
        .limit(this.query.limit)
        .startAfter(cursor)
    })
    this.mapAndUpdate(more)
  }

  // Determines the doc snapshot to paginate query 
  private getCursor() {
    const current = this._data.value
    if (current.length) {
      return this.query.prepend ? current[0].doc : current[current.length - 1].doc
    }
    return null
  }

  // Maps the snapshot to usable format the updates source
  private mapAndUpdate(col: AngularFirestoreCollection<any>) {

    if (this._done.value || this._loading.value) { return };

    // loading
    this._loading.next(true)
    return col.snapshotChanges().pipe(
      tap(arr => {
        let values = arr.map(snap => {
          const data = snap.payload.doc.data()
          const doc = snap.payload.doc
          return { ...data, doc }
        })
        values = this.query.prepend ? values.reverse() : values
        this._data.next(values)
        this._loading.next(false)
        if (!values.length) {
          this._done.next(true)
        }
        take(1)
      })
    ).subscribe()

  }
  // Reset the page
  reset() {
    this._data.next([])
    this._done.next(false)
  }

}
