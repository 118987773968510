import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toast-bar',
  templateUrl: './toast-bar.component.html',
  styleUrls: ['./toast-bar.component.scss']
})
export class ToastBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
