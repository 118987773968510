import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { LoginComponent } from './shared/login/login.component';
import { RegisterComponent } from './register/register.component';
import { UserComponent } from './user/user.component';
import { IconComponent } from './icon/icon.component';
import { DataComponent } from './data/data.component';
import { ShellComponent } from './core/shell/shell.component';
import { CategoryComponent } from './category/category.component';
import { MarkerComponent } from './marker/marker.component';


const routes: Routes = [
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  { path: '', component: RegisterComponent, pathMatch: 'full'},
  {
    path:'', component: ShellComponent, canActivate:[AuthGuard],
    children:[
      {
        path: 'users', component: UserComponent
      },
      {
        path: 'icons', component: IconComponent
      },
      {
        path: 'data', component: DataComponent
      },
      {
        path: 'categories', component: CategoryComponent
      },
      {
        path: 'markers', component: MarkerComponent
      }
    ]
  },
  // { path: 'login', component: LoginComponent,},
  // { path: 'users', component: UserComponent, canActivate: [AuthGuard]},
  // { path: 'icons', component: IconComponent, canActivate: [AuthGuard]},
  // { path: 'data', component: DataComponent, canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent,},
  {
    path: '404',
    component: NotFoundComponent,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
