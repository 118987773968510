import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../modal/modal.component';

@Component({
  selector: 'app-dialog-marker',
  templateUrl: './dialog-marker.component.html',
  styleUrls: ['./dialog-marker.component.scss']
})
export class DialogMarkerComponent{

  constructor(public dialogRef: MatDialogRef<DialogMarkerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private dialogRefe: MatDialog) { }

  close(){
    this.dialogRefe.closeAll()
  }
  callOpen(num_call) {
    const call = "tel:" + num_call
    window.open(call, '_blank');
  }
  messageOpen(num_call) {
    let num = String(num_call).replace(/\s/g, "");
    const call = "https://wa.me/" + num + "?text=Informacion%20sobre"
    window.open(call, '_blank');
  }
  openWebsite(site) {
    const website = (site.indexOf('://') === -1) ? 'http://' + site : site;
    window.open(website, '_blank');
  }

}
