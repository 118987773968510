import { Injectable, Pipe } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject, Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import { GeoFirestore } from 'geofirestore';
import { tap, scan, take } from 'rxjs/operators'
// import 'rxjs/operators/do';
// import 'rxjs/add/operator/scan';
// import 'rxjs/add/operator/take';

// Options to reproduce firestore queries consistently
interface QueryConfig {
  path: string, // path to collection
  field: string, // field to orderBy
  limit?: number, // limit per query
  reverse?: boolean, // reverse order?
  prepend?: boolean // prepend to source?
}

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  // Source data
  private _done = new BehaviorSubject(false);
  private _loading = new BehaviorSubject(false);
  private _data = new BehaviorSubject([]);

  private query: QueryConfig;

  // Observable data
  data: Observable<any>;
  done: Observable<boolean> = this._done.asObservable();
  loading: Observable<boolean> = this._loading.asObservable();

  constructor(public db: AngularFirestore, public store: AngularFireStorage, private dbd: AngularFireDatabase) { }
  getCategory(code) {
    return this.db.collection('categories', ref => ref.where('code', '==', code).where('state', '==', true).limit(1)).get();
  }
  getCategoryState(state){
    return this.db.collection('categories',ref => ref.where('state','==',state).orderBy('name',"asc")).valueChanges();
  }
  getALL(){
    return this.db.collection('categories', ref => ref.where('state', '==', true)).valueChanges();
  }
  getCategories(limit){
    return this.db.collection('categories',ref => ref.orderBy('name',"asc").limit(limit)).snapshotChanges();
  }
  getCount(){
    const ref = this.db.collection('categories').doc('count');
    return ref.valueChanges();
  }
  getPrevPage(prevestart,firtsreponse,limit){
    return this.db.collection('categories',ref => ref.orderBy('name',"asc").startAt(prevestart).endBefore(firtsreponse).limit(limit)).get();
  }
  getNextPage(limit,lastresponse){
    return this.db.collection('categories',ref => ref.limit(limit).orderBy('name',"asc").startAfter(lastresponse)).get();
  }
  createCategory(data){
    const ref = this.db.collection('categories').add(data);
    this.contador('new');
    return ref;
  }
  contador(typ){
    let num = 0;
    const ref = this.db.collection('categories').doc('count').get();
    ref.subscribe(r=>{
      if(typ == 'new'){
        num = r.data()['page_size'] + 1;
        this.updateContador(num);
      }else{
        num = r.data()['page_size'] - 1;
        this.updateContador(num);
      }
    })
  }
  updateContador(num){
    this.db.collection('categories').doc('count').update({'page_size':num});
  }
  deleteCategory(id){
    const ref = this.db.collection('categories').doc(id).delete();
    this.contador('del');
    return ref
  }
  updateCategory(id,data){
    this.db.collection('categories').doc(id).update(data);
  }
  initCategories(path, field, opts?) {
    this.query = {
      path,
      field,
      limit: 8,
      reverse: false,
      prepend: false,
      ...opts
    }
    const first = this.db.collection(this.query.path, ref => {
      return ref
        .where('state', '==', true)
        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
        .limit(this.query.limit)
    })
    this.mapAndUpdate(first)
    // Create the observable array for consumption in components
    this.data = this._data.asObservable().pipe(
      scan((acc, val) => {
        return this.query.prepend ? val.concat(acc) : acc.concat(val)
      })
    )
  }
  // Retrieves additional data from firestore
  more() {
    const cursor = this.getCursor()

    const more = this.db.collection(this.query.path, ref => {
      return ref
        .where('state', '==', true)
        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
        .limit(this.query.limit)
        .startAfter(cursor)
    })
    this.mapAndUpdate(more)
  }

  // Determines the doc snapshot to paginate query 
  private getCursor() {
    const current = this._data.value
    if (current.length) {
      return this.query.prepend ? current[0].doc : current[current.length - 1].doc
    }
    return null
  }

  // Maps the snapshot to usable format the updates source
  private mapAndUpdate(col: AngularFirestoreCollection<any>) {

    if (this._done.value || this._loading.value) { return };

    // loading
    this._loading.next(true)
    return col.snapshotChanges().pipe(
      tap(arr => {
        let values = arr.map(snap => {
          const data = snap.payload.doc.data()
          const doc = snap.payload.doc
          return { ...data, doc }
        })
        values = this.query.prepend ? values.reverse() : values
        this._data.next(values)
        this._loading.next(false)
        if (!values.length) {
          this._done.next(true)
        }
        take(1)
      })
    ).subscribe()

  }
  // Reset the page
  reset() {
    this._data.next([])
    this._done.next(false)
  }
}
