import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  menus = [{
    title: 'Home',
    description: '',
    icon: 'home',
    children:[{link:'/',title:'Principal',icon:'keyboard'}]
  },{
    title: 'Administracion',
    description: '',
    icon: 'dashboard',
    children:[
      {link:'/users',title:'Usuarios',icon:'keyboard'},
      {link:'/icons',title:'Iconos',icon:'keyboard'},
      {link:'/data',title:'Data',icon:'keyboard'},
      {link:'/markers',title:'Markers',icon:'keyboard'},
      {link:'/categories',title:'Categories',icon:'keyboard'},
    ]
  },{
    title: 'Reportes',
    description: '',
    icon: 'insert_chart',
    children:[{link:'/report',title:'Registros',icon:'keyboard'}]
  }]

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
  }
  goIntitec() {
    window.open('https://intitecperu.com/contactus', '_blank');
  }

}
