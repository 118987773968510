import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { AuthService } from '../services/auth.service';
import { FirebaseService } from 'src/services/firebase.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalIconComponent } from '../shared/modal-icon/modal-icon.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
export interface Section {
  name: string;
  description: string;
  icon: string;
}

export interface UserData {
  id: string;
  name: string;
  icon_url: string;
  user:string;
  state: string;
  option: string;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
  'maroon', 'red', 'orange', 'yellow', 'olive', 'green', 'purple', 'fuchsia', 'lime', 'teal',
  'aqua', 'blue', 'navy', 'black', 'gray'
];
const NAMES: string[] = [
  'Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack', 'Charlotte', 'Theodore', 'Isla', 'Oliver',
  'Isabella', 'Jasper', 'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'
];

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  

  displayedColumns: string[] = ['id', 'nombre', 'icon', 'usuario','estado','opcion'];
  dataSource: MatTableDataSource<any[]>;
  dataList = [];
  items: Array<any>;
  url_term = '';
  registros: Section[] = [
    {
      name: 'Incidencias',
      description: '',
      icon: 'keyboard'
    },
  ];
  reportes: Section[] = [
    {
      name: 'Incidencias Usuarios',
      description: '',
      icon: 'reorder'
    },
  ];
  about = [
    {
      name: 'Inti Tec',
      description: '',
      icon: 'business',
      code:"INTITEC"
    },
    {
      name: 'Términos y',
      description:"Condiciones",
      icon:'menu_book',
      code:"TERM"
    }
  ]
  user_json = {};
  public type_mode='over';
  admin: boolean;
  indeterminate = true;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
 
  constructor(public auth: AuthService,public firebaseService: FirebaseService,public dialog: MatDialog,private breakpointObserver: BreakpointObserver) {
    // Create 100 users
    const users = Array.from({length: 100}, (_, k) => createNewUser(k + 1));

    // Assign the data to the data source for the table to render
    // this.dataSource = new MatTableDataSource(users);
    auth.user$.subscribe((res) => {
      if(res){
        this.user_json = {
          uid: res['uid'],
          name: res['email']
        }
        this.indeterminate = false;
        this.admin = true;
      }else{
        this.admin = false
        this.indeterminate = false;
      }
     
    })
  }

  ngOnInit() {
    this.getAll();
    this.readTerm();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getAll(){
    this.firebaseService.getIconsAll().subscribe(result => {
      this.items = result;
      for (const i in this.items) {
        if (result.hasOwnProperty(i)) {
          const element = this.items[i];
          this.dataList.push({
            id: element.payload.doc.id,
            name: element.payload.doc.data().name,
            icon_url: element.payload.doc.data().url_icon,
            user: element.payload.doc.data().user_name,
            state: element.payload.doc.data().state,
            option:element.payload.doc.id,
          })
        }
      }
      this.dataSource = new MatTableDataSource(this.dataList);
    });
  }
  openModalRegister():void{
    const dialogRef = this.dialog.open(ModalIconComponent, {
      width: '800px',
      data:{name:'',state:0}
    });

    dialogRef.afterClosed().subscribe(commet => {
      this.dataList=[];
      this.getAll()

    });
  }
  editIcon(id){

    this.firebaseService.getIconIdData(id).subscribe(results => {
      console.log('results: ', results);
      const items_data:any = results;

      items_data['uid']=id;
      this.firebaseService.getUser(results['user_name']).subscribe(r=>{
        items_data['user'] = r[0];
      })
      this.editModal(items_data);
    });

  }
  editModal(data):void{
    const dialogRefEdit = this.dialog.open(ModalIconComponent, {
      width: '800px',
      data:data
    });
    dialogRefEdit.afterClosed().subscribe(commet => {
      this.dataList=[];
      this.getAll()

    });
  }
  deleteIcon(id){
    this.firebaseService.deleteIcon(id).then(res =>{
      this.dataList=[];
      this.getAll()
    });
  }
  goIntitec(type) {
    if(type.code === "INTITEC"){
      window.open('https://intitecperu.com/contactus', '_blank');
    }
    if(type.code === "TERM"){
      window.open(this.url_term, '_blank');
    }
  }
  readTerm(){
    this.firebaseService.getTerm().subscribe(res =>{
      this.url_term = res[0].payload.doc.data()['url'];
    });
  }
  avatar(){
    return ('../../assets/images/avatar.png');
  }
}

/** Builds and returns a new User. */
function createNewUser(id: number): UserData {
  const name = NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' +
      NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';

  return {
    id: id.toString(),
    name: name,
    icon_url: Math.round(Math.random() * 100).toString(),
    user: name,
    state: COLORS[Math.round(Math.random() * (COLORS.length - 1))],
    option:id.toString()
  };
}
