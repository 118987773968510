import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit {
  @Input() public data_read;
  @Input() public src;

  constructor() { }

  ngOnInit() {
    console.log('this data',this.data_read);
  }

}
