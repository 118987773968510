import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

      return this.auth.user$.pipe(
           take(1),
           map(user => !!user), // <-- map to boolean
           tap(loggedIn => {
             if (!loggedIn) {
               this.router.navigate(['/login']);
             }

            //  else{
            //   this.auth.user$.subscribe(res=>{
            //     if(res['state'] == 1){
            //       console.log('');
            //     }else{
            //       if(res['state']!=0){
            //         console.log('');
            //       }else{
            //        this.router.navigate(['/']);
            //       }
                 
            //     }
            //   });
            // }
         })
    )
  }
}
