import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ToastBarComponent } from '../toast-bar/toast-bar.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  defaultConf = {
    message: '',
    actionButtonLabel: 'Cerrar',
    verticalPosition: 'top',
    horizontalPosition: 'right',
    action: true,
    setAutoHide: true,
    autoHide: 2000,
    addExtraClass: []
  };

  constructor(public auth:AuthService,private fb: FormBuilder,private _snackBar: MatSnackBar) { }
  profileForm = this.fb.group({
    email: ['',[Validators.required,Validators.email]],
    password: ['',Validators.required],
  });

  ngOnInit() {
  }
  login(data){
    const value =  this.auth.login(data.value.email,data.value.password);
    value.then((res=>{
      this.open({message: 'Acceso exitoso', addExtraClass: ['alert-success']});

    }),err =>{
      this.open({message:'Acceso denegado', addExtraClass: ['alert-error']});

    });
  }
  open(parameters) {
    const params = Object.assign({}, this.defaultConf, parameters);
    const config = new MatSnackBarConfig();
    config.verticalPosition = params.verticalPosition;
    config.horizontalPosition = params.horizontalPosition;
    config.duration = params.setAutoHide ? params.autoHide : 0;
    config.panelClass = params.addExtraClass;
    this._snackBar.open(params.message, params.action ? params.actionButtonLabel : undefined, config);
  }
  get registerFormControl() {
    return this.profileForm.controls;
  }
  getErrorMessage() {
    if (this.profileForm.controls.email.hasError('required')) {
      return 'Campo requerido';
    }

    return this.profileForm.controls.email.hasError('email') ? 'Email inválido' : '';
  }
  getErrorMessagePassword() {
    return this.profileForm.controls.password.hasError('required') ? 'Campo requerido' : '';
  }

}



