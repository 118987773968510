import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import { GeoFirestore } from 'geofirestore';

@Injectable({
  providedIn: 'root'
})
export class TypeService {

  constructor(public db: AngularFirestore, public store: AngularFireStorage, private dbd: AngularFireDatabase) { }
  getType(code){
    return this.db.collection('types',ref => ref.where('code','==',code).where('state','==',true).limit(1)).get();
  }
  getTypeState(state){
    return this.db.collection('types',ref => ref.where('state','==',state)).valueChanges();
  }
}
