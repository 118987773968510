import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-regis-bodega",
  templateUrl: "./regis-bodega.component.html",
  styleUrls: ["./regis-bodega.component.scss"],
})
export class RegisBodegaComponent implements OnInit {
  @Input() show: boolean;
  @Output() val_return = new EventEmitter<boolean>();
  // show = false;

  constructor() {}

  ngOnInit() {}
  open() {
    this.show = true;
    this.val_return.emit(true);
  }
  hide() {
    this.show = false;
    this.val_return.emit(false);
  }
  goToSub(){
    window.open('https://bodegasdelperu.com/', '_blank');
  }
  goToPol(){
    window.open('https://firebasestorage.googleapis.com/v0/b/tubodega-36344.appspot.com/o/term%2FPol%C3%ADticas%20de%20Privacidad.pdf?alt=media&token=69831da0-297c-4dd7-9b50-c957491adf03', '_blank');
  }
  goToTerm(){
    window.open('https://firebasestorage.googleapis.com/v0/b/tubodega-36344.appspot.com/o/term%2FPol%C3%ADticas%20de%20Privacidad.pdf?alt=media&token=69831da0-297c-4dd7-9b50-c957491adf03', '_blank');
  }
}
