import { Component, ViewChild, ElementRef, NgZone, OnInit, AfterViewInit, ChangeDetectorRef, Inject, QueryList, ViewChildren } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subject, combineLatest } from 'rxjs';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { FirebaseService } from 'src/services/firebase.service';
import { ModalComponent } from '../shared/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import * as firebaseApp from 'firebase/app';
import * as geofirex from 'geofirex';
import { MarkerService } from 'src/services/marker.service';
import { MatSliderChange } from '@angular/material/slider';
import { SwiperOptions } from 'swiper';
import { parse } from 'querystring';
import { IconService } from 'src/services/icon.service';
import { CategoryService } from 'src/services/category.service';
import { DialogMarkerComponent } from '../shared/dialog-marker/dialog-marker.component';
import { AgmSnazzyInfoWindow } from '@agm/snazzy-info-window';
export interface Section {
  name: string;
  description: string;
  icon: string;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements AfterViewInit {
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;
  // @ViewChild('infoWindow', {static: false}) public infoWind: AgmSnazzyInfoWindow;
  @ViewChildren(AgmSnazzyInfoWindow) snazzyWindowChildren: QueryList<AgmSnazzyInfoWindow>;

  admin: boolean;
  indeterminate = true;
  items: Array<any>;
  title: string = 'AGM project';
  latitude: number = -12.0463731;
  longitude: number = -77.042754;
  zoom: number = 14;
  address: string;
  private geoCoder;
  visible = true;
  selectable = true;
  data = []
  lat = 43.879078;
  lng = -103.4615581;
  name: string;
  user_json = {};
  show = false;
  search_buss = 'BUSCAR NECOGIO';
  show_category = false;
  show_info_data = false;
  cat_name = 'Bodegas';

  markers = [];
  markers_default = [];
  icons_filters = [];
  url_term = '';
  center: google.maps.LatLngLiteral;
  mapa;
  circleRadius: number = 2000;
  circle_lat: number;
  circle_lon: number;
  geo = geofirex.init(firebaseApp);
  points: Observable<any>;
  searchterm: string;
  slide_data = []
  list_cate = [];
  filter_markers = []

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );


  ngAfterViewInit() {

  }

  constructor(private breakpointObserver: BreakpointObserver, public auth: AuthService, private mapsAPILoader: MapsAPILoader,
    public firebaseService: FirebaseService, public categoryService: CategoryService, public markerService: MarkerService, public iconService: IconService, public dialog: MatDialog, private ngZone: NgZone) {
    auth.user$.subscribe((res) => {
      if (res) {
        this.user_json = {
          uid: res['uid'],
          name: res['email']
        }
        this.indeterminate = false;
        this.admin = true;
      } else {
        this.admin = false
        this.indeterminate = false;
      }

    })
  }

  autoTicks = false;
  disabled = false;
  invert = false;
  max = 5000;
  min = 1000;
  showTicks = false;
  step = 1000;
  thumbLabel = true;
  vertical = false;
  get tickInterval(): number | 'auto' {
    return this.showTicks ? (this.autoTicks ? 'auto' : this._tickInterval) : 0;
  }
  set tickInterval(value) {
    this._tickInterval = coerceNumberProperty(value);
  }
  private _tickInterval = 1;

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'km';
    }

    return value;
  }

  ngOnInit() {
    navigator.geolocation.getCurrentPosition(position => {
      console.log('position: ', position);
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      // this.marker_nav(this.center.lat,this.center.lng);
      console.log('this.center: ', this.center);
    });
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: []
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 14;
          this.markers_default = [];
          this.marker_nav(this.latitude, this.longitude);
        });
      });
    });
    this.readTerm();
    this.getLeyendIcons();
  }

  updateSetting(event: MatSliderChange) {
    this.circleRadius = event.value;
    this.updateMapCenter();
  };
  // Get Current Location Coordinates
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 14;
        this.markers_default = [];
        this.marker_nav(this.latitude, this.longitude);
        // this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  public getMapInstance(mape) {
    this.mapa = mape;
  }

  public updateMapCenter() {
    this.markers = [];
    this.slide_data = [];
    this.latitude = this.mapa.center.lat();
    this.longitude = this.mapa.center.lng();
    this.zoom = 14;
    this.markers_default = []

    this.marker_nav(this.mapa.center.lat(), this.mapa.center.lng());
    const mar = this.markerService.getMarkersRadio(this.latitude, this.longitude, this.circleRadius / 1000);
    mar.categogy.subscribe(resp => {
      const cate_list = resp;
      mar.query.then(r => {
        const unique = [];
        this.list_cate = [];
        for (let i = 0; i < r.docs.length; i++) {
          const element = r.docs[i];
          let icon_marker = {};
          if (element.data().icon) {
            icon_marker = {
              url: element.data().icon, scaledSize: {
                width: 20,
                height: 30
              }
            }
          }
          let title = cate_list.filter(res => res['code'] === element.data()['type_category'])
          if (!unique[element.data()['type_category']]) {
            this.list_cate.push({
              code: title[0]['code'],
              name: title[0]['name']
            })
            unique[element.data()['type_category']] = 1;
          }
          this.markers.push(
            {
              lat: element.data().latitude, lng: element.data().longitude, alpha: 1, message: element.data().name,
              icon: icon_marker, number: element.data().number_phone, site: element.data().site_web,
              title: title[0]['name'], code_cat: title[0]['code'], code: element.data().code
            });
        }
      })
      // mar.query.get().then(r => {
      //   this.slide_data = r.docs;

      // r.docs.forEach(response => {
      // let icon_marker = {};
      // if (response.data().icon) {
      //   icon_marker = {
      //     url: response.data().icon, scaledSize: {
      //       width: 20,
      //       height: 30
      //     }
      //   }
      // }
      //   let title = cate_list.filter(res => res['code'] === response.data().type_category)
      // this.markers.push(
      //   {
      //     lat: response.data().latitude, lng: response.data().longitude, alpha: 1, message: response.data().name,
      //     icon: icon_marker, number: response.data().number_phone, site: response.data().site_web,
      //     title: title[0]['name'],code_cat:title[0]['code'], code: response.data().code
      //   });
      //   if(type_c != title[0]['code']){
      //     this.list_cate.push({
      //       name: title[0]['name'],
      //       code: title[0]['code']
      //     })
      //     type_c = title[0]['code']
      //   }
      //   if (r.docs.hasOwnProperty(response.data())) {
      //     const element = r.docs[response.data()];
      //     console.log('element',element)
      //     // if (!unique[element.payload.doc.data()['code']]) {
      //     //   this.icons_filters.push({ 'code': element.payload.doc.data()['code'], name: element.payload.doc.data()['name'], icon: element.payload.doc.data()['url_icon'] });
      //     //   unique[element.payload.doc.data()['code']] = 1;
      //     // }
      //   }

      // })
      //   console.log("RegisterComponent -> updateMapCenter -> this.markers", this.markers)
      //   console.log('categorias',this.list_cate)
      // })
    })

  }
  marker_nav(la, lo) {
    this.markers_default = [];
    this.markers_default.push({
      latitude: la,
      longitude: lo,
      icon: { url: this.markerIconUrl(), scaledSize: { width: 35, height: 35 } }
    });
    this.circle_lat = la;
    this.circle_lon = lo;
  };
  markerIconUrl() {
    return ('../../assets/images/map-marker.png');
  }
  goIntitec(type) {
    if (type.code === "INTITEC") {
      window.open('https://intitecperu.com/contactus', '_blank');
    }
    if (type.code === "TERM") {
      window.open(this.url_term, '_blank');
    }
  }
  readTerm() {
    this.firebaseService.getTerm().subscribe(res => {
      this.url_term = res[0].payload.doc.data()['url'];
    });
  }
  avatar() {
    return ('../../assets/images/avatar.png');
  }

  getLeyendIcons() {
    this.iconService.getAllIcons().subscribe(res => {
      this.icons_filters = res;
    })
  }

  goToLink(url) {
    if (url != '') {
      window.open(url, '_blank');
    }
  }
  openInfo() {
    this.show = true;
    this.search_buss = "buscar negocio";
    this.show_category = false;
    this.getCategories();
  }
  closeInfo() {
    this.show = false;
    this.categoryService.reset();
    this.markerService.reset();
  }
  openCategory(category) {
    this.filter_markers = [];
    this.search_buss = "categoria:" + ' ' + category['code'];
    this.show_category = true;
    this.filter_markers = this.markers.filter(res => res['code_cat'] === category['code'])
    this.cat_name = category['name'];
    // this.markerService.initMarkerfilters('markers', 'name', category, { reverse: false, prepend: false })
  }
  goMarker(item) {
    // this.latitude = item['lat'];
    // this.longitude = item['lng'];
    // this.zoom = 14;
    // this.marker_nav(item['lat'], item['lng']);
    // this.openDialog(item);
    const info = this.snazzyWindowChildren['_results'].filter(res => res['_marker']['latitude'] == item['lat'] && res['_marker']['longitude'] == item['lng']);
    info[0]._openInfoWindow();
  }
  onScroll() {
    this.categoryService.more()
  }
  scrollHandler(e) {
    if (e === 'bottom') {
      this.categoryService.more()
    }


  }
  getCategories() {
    this.categoryService.initCategories('categories', 'name', { reverse: false, prepend: false })

  }
  getMoreFilters() {
    // this.markerService.more()
  }
  onMouseOut(infoWindow, $event: MouseEvent) {
    infoWindow._closeInfoWindow();
  }
  callOpen(num_call) {
    const call = "tel:" + num_call
    window.open(call, '_blank');
  }
  messageOpen(num_call) {
    let num = String(num_call).replace(/\s/g, "");
    const call = "https://wa.me/" + num + "?text=Informacion%20sobre"
    window.open(call, '_blank');
  }
  openWebsite(site) {
    const website = (site.indexOf('://') === -1) ? 'http://' + site : site;
    window.open(website, '_blank');
  }
  openDialog(data): void {
    const data_send = {}
    let title = this.list_cate.filter(res => res['code'] === data['code_cat'])
    if (data['code'] == 'market') {
      data_send['title'] = title[0].name;
      data_send['name'] = data['message'];
      data_send['number'] = data['number'];
      data_send['site_web'] = data['site'];
    } else {
      data_send['title'] = title[0].name;
      data_send['name'] = data['message'];
      data_send['number'] = data['number'];
      data_send['site_web'] = data['site'];
    }
    const dialogRef = this.dialog.open(DialogMarkerComponent, {
      width: '250px',
      backdropClass: '',
      disableClose: false,
      hasBackdrop: false,
      maxHeight: '150px',
      data: data_send,
      panelClass: data['code'] == 'market' ? 'modal-bodega' : 'modal-tienda'
    });
  }
  openFilter() {
    if (this.show == false) {
      this.show = true;
    } else {
      this.show = false;
    }
  }
  openInfoData() {
    if (this.show_info_data == false) {
      this.show_info_data = true;
    } else {
      this.show_info_data = false
    }
    console.log('this.show_info_data',this.show_info_data)

  }
  changeval(val: boolean){
    this.show_info_data = val;
  }

}
