import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable, of } from 'rxjs';
import { User } from './user.model';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { switchMap } from 'rxjs/operators';
import { auth } from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<User>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }
  async login(email: string, password: string){
    const credential =  await this.afAuth.auth.signInWithEmailAndPassword(email,password);
    this.updateUserData(credential.user);
    return this.router.navigate(['home']);

  }
  

  async signOut(){
    await this.afAuth.auth.signOut();
    return this.router.navigate(['login']);
  }

  private updateUserData(user){
    const userRef : AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    userRef.get().subscribe(response =>{
      const data={
        uid:user.uid,
        email:user.email,
        displayName:user.displayName,
        photoURL:user.photoURL,
      };
      return userRef.set(data, {merge:true})
    })
  }
}
