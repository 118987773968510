import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map, shareReplay, tap } from 'rxjs/operators';
import { FirebaseService } from 'src/services/firebase.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
export interface Section {
  name: string;
  description: string;
  icon: string;
}

@Component({
  selector: 'app-terminos',
  templateUrl: './terminos.component.html',
  styleUrls: ['./terminos.component.scss']
})
export class TerminosComponent implements OnInit {

  user_json = {};
  public type_mode='over';
  admin: boolean;
  indeterminate = true;
  data = {};
  url="";

  constructor(public auth: AuthService,private breakpointObserver: BreakpointObserver,public firebaseService: FirebaseService, private http:HttpClient) { 
    auth.user$.subscribe((res) => {
      if(res){
        this.user_json = {
          uid: res['uid'],
          name: res['email']
        }
        this.indeterminate = false;
        this.admin = true;
      }else{
        this.admin = false
        this.indeterminate = false;
      }
     
    })
    this.readTerm();
  }

  registros: Section[] = [
    {
      name: 'Incidencias',
      description: '',
      icon: 'keyboard'
    },
  ];
  reportes: Section[] = [
    {
      name: 'Incidencias Usuarios',
      description: '',
      icon: 'reorder'
    },
  ];
  about = [
    {
      name: 'Inti Tec',
      description: '',
      icon: 'business',
      code:"INTITEC"
    },
    {
      name: 'Términos y',
      description:"Condiciones",
      icon:'menu_book',
      code:"TERM"
    }
  ]
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  ngOnInit() {
    
  }
  goIntitec(type){
    if(type.code === "INTITEC"){
      window.open('https://intitecperu.com/contactus', '_blank');
    }
    if(type.code === "TERM"){
      console.log('VAMOS A LEER PDF');
    }
  }
  avatar(){
    return ('../../assets/images/avatar.png');
  }
  pdf(){
    return ('../../assets/pdfs/politicas_de_privacidad.pdf')
  }
  readTerm(){
    this.firebaseService.getTerm().subscribe(res =>{
      this.data = res[0].payload.doc.data();
      this.url = res[0].payload.doc.data()['url'];
    });
  }

}
