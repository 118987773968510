import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import { GeoFirestore } from 'geofirestore';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor(public db: AngularFirestore, public store: AngularFireStorage, private dbd: AngularFireDatabase) { }
  getIcon(code){
    return this.db.collection('icons',ref => ref.where('code','==',code).where('state','==',1).limit(1)).get();
  }
  getAllIcons(){
    return this.db.collection('icons', ref => ref.where('state','==',1).orderBy('name')).valueChanges();
  }
  getIconState(state){
    return this.db.collection('icons',ref => ref.where('state','==',state).orderBy('name')).valueChanges();
  }
}
